<template>
  <div id="app">
    <div class="nav" v-if="this.loggedIn">
      <fa class="transition mx-4 text-gray cursor-pointer hover:text-gray-darker" icon="bars"
          @click="drawerOpened = !drawerOpened"></fa>
      <router-link class="mx-auto h-full" to="/">
        <img class="h-full" alt="logo" src="/img/logo.svg" @click="drawerOpened = false">
      </router-link>
      <span class="text-xs text-gray mr-2" :class="{'text-warning': newVersionAvailable}">
        u{{ userId }} – {{ hash }}@{{ version }}
      </span>
      <fa class="animate-pulse text-sm mr-2" :class="{'text-success': queueActive, 'text-warning': !queueActive}"
          icon="history"></fa>
      <span class="animate-pulse h-3 w-3 mr-4 rounded-full bg-success opacity-75"
            :class="{'bg-danger': !isOnline}"
      ></span>
    </div>

    <div class="w-screen top-10 left-0 text-center mt-10">
      <transition name="alert">
        <t-alert v-if="alertMessage" :show="!!alertMessage" :timeout="5000" :variant="alertVariant"
                 :dismissible="false"
        >
          <fa v-if="alertIcon" :icon="alertIcon"></fa>
          {{ alertMessage }}
          <button v-if="alertAction" @click="alertAction.action" class="btn btn-sm btn-primary">{{ alertAction.text }}</button>
        </t-alert>
      </transition>
    </div>

    <div class="container relative min-h-screen mx-auto p-4 mt-10 overflow-hidden">
      <transition v-if="this.loggedIn" :name="transition">
        <router-view @alert="alert"/>
      </transition>
      <login v-else @login="fetchData"></login>
    </div>

    <drawer top="10" :opened="drawerOpened" @close="drawerOpened = false">
      <div class="mt-6 relative flex-1 px-4">
        <div v-if="deferredPrompt"
             class="flex items-center transition p-6 text-info-dark cursor-pointer hover:text-info"
             @click="install">
          <fa icon="download" class="mr-4"></fa>
          <div class="w-full">Auf Gerät installieren</div>
        </div>
        <div class="flex items-center transition p-6 text-gray-darker cursor-pointer hover:text-brand"
             @click="fetchAllChecklists">
          <fa icon="cloud-download-alt" class="mr-4"></fa>
          <div class="w-full">Prüfungskataloge herunterladen</div>
          <Loading class="ml-4 w-8" v-if="checklistsDownloading"></Loading>
        </div>
        <div class="flex items-center transition p-6 text-gray-darker cursor-pointer hover:text-brand"
             @click="fetchAllAudits">
          <fa icon="cloud-download-alt" class="mr-4"></fa>
          <div class="w-full">Alte Prüfungsdaten herunterladen</div>
          <Loading class="ml-4 w-8" v-if="auditsDownloading"></Loading>
        </div>
        <div class="flex items-center transition p-6 text-gray-darker cursor-pointer hover:text-brand"
             @click="fetchAllCustomers">
          <fa icon="cloud-download-alt" class="mr-4"></fa>
          <div class="w-full">Kundendaten herunterladen</div>
          <Loading class="ml-4 w-8" v-if="customersDownloading"></Loading>
        </div>
        <div class="flex items-center transition p-6 text-gray-darker cursor-pointer hover:text-brand"
             @click="fetchAllSchedules">
          <fa icon="cloud-download-alt" class="mr-4"></fa>
          <div class="w-full">Planungen herunterladen</div>
          <Loading class="ml-4 w-8" v-if="schedulesDownloading"></Loading>
        </div>
        <div class="flex items-center transition p-6 text-gray-darker cursor-pointer hover:text-brand"
             @click="fetchAllEquipment">
          <fa icon="cloud-download-alt" class="mr-4"></fa>
          <div class="w-full">Geräte herunterladen</div>
          <Loading class="ml-4 w-8" v-if="equipmentDownloading"></Loading>
        </div>
        <router-link class="w-100" to="/queue">
          <div class="p-6 text-gray-darker cursor-pointer hover:text-brand" @click="drawerOpened = false">
            <fa icon="history" class="mr-4"></fa>
            Warteschlange
          </div>
        </router-link>
        <router-link class="w-100" to="/log">
          <div class="p-6 text-gray-darker cursor-pointer hover:text-brand" @click="drawerOpened = false">
            <fa icon="trash-restore-alt" class="mr-4"></fa>
            Log
          </div>
        </router-link>
        <router-link class="w-100" to="/help">
          <div class="p-6 text-gray-darker cursor-pointer hover:text-brand" @click="drawerOpened = false">
            <fa icon="question-circle" class="mr-4"></fa>
            Hilfe
          </div>
        </router-link>
        <div class="p-6 text-danger cursor-pointer" @click="logout">
          <fa icon="sign-out-alt" class="mr-4"></fa>
          Ausloggen
        </div>
      </div>
      <div class="flex px-4 flex-1 items-end">
        <div class="px-6">
        <span class="text-xs text-gray mr-2">
            Nutzer-ID: {{ userId }}<br>
            Hash: {{ hash }}<br>
            Version: {{ version }}
            <span v-if="newVersionAvailable" class="text-warning"><br>Neue Version verfügbar! Bitte Seite aktualisieren.</span>
          </span>
        </div>
      </div>
    </drawer>
  </div>
</template>

<script>
import Login from '@/views/Login'
import store from '@/store'
import Drawer from '@/components/Drawer'
import Loading from '@/components/Loading'
import api from '@/api'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export default {
  name: 'App',
  components: {Loading, Drawer, Login},
  data: () => ({
    alertIcon: null,
    alertAction: null,
    alertMessage: '',
    alertVariant: 'danger',
    currentRepoVersion: null,
    auditsDownloading: false,
    checklistsDownloading: false,
    customersDownloading: false,
    equipmentDownloading: false,
    schedulesDownloading: false,
    deferredPrompt: null,
    drawerOpened: false,
    transition: 'slide-right',
    queueActive: false,
  }),
  computed: {
    hash() {
      if (process.env.VUE_APP_GIT_HASH) {
        return process.env.VUE_APP_GIT_HASH.substring(0, 8)
      }
      return 'no rev'
    },
    loggedIn() {
      return store.getters.authenticated
    },
    version() {
      if (process.env.VUE_APP_VERSION) {
        return process.env.VUE_APP_VERSION
      }
      return 'no version'
    },
    userId() {
      if (store.state.user) {
        return store.state.user.id
      }
      return null
    },
    isOnline() {
      return store.state.online
    },
    isDownloading() {
      return this.checklistsDownloading ||
          this.customersDownloading ||
          this.schedulesDownloading ||
          this.equipmentDownloading
    },
    newVersionAvailable() {
      return this.currentRepoVersion !== null && this.hash !== this.currentRepoVersion
    },
  },
  created() {
    window.addEventListener("beforeunload", function (e) {
      if (store.state.online) {
        delete e['returnValue'];
      } else {
        e.preventDefault();
        e.returnValue = ''
      }
    })

    if (this.$workbox) {
      this.accept()
    }

    dayjs.extend(isSameOrAfter)

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.deferredPrompt = e
    })

    window.addEventListener('offline', () => this.setOnline(false))
    window.addEventListener('online', () => this.setOnline())

    this.pingServer()
  },
  mounted() {
    this.hasApiConnection()
        .then(() => {
          if (this.loggedIn) {
            this.fetchData()
            window.setInterval(this.fetchData, 300000)
          }
        })
        .catch(() => this.setOnline(false))
    this.$forceUpdate()

    this.fetchCurrentVersionFromRepo()
  },
  methods: {
    async accept() {
      await this.$workbox.messageSW({type: 'SKIP_WAITING'})
    },
    alert(type, message, action = null) {
      scrollTo(0, 0)
      this.alertVariant = type
      this.alertMessage = message
      this.alertAction = action

      setTimeout(() => this.alertMessage = null, 5000)
    },
    fetchAllAudits() {
      const schedules = store.state.modules.schedules
      const filters = [
        {
          column: "next_audit",
          operator: "<=",
          value: dayjs().add(30, 'day'),
        },
        {
          column: "next_audit",
          operator: ">",
          value: dayjs().subtract(2, 'day'),
        }
      ]
      if (schedules && schedules.length > 0) {
        let customerIds = schedules.map(schedule => schedule.customer.id)
        filters.push({
          column: "customer.id",
          operator: "in",
          value: customerIds,
        })
      }
      this.auditsDownloading = true
      this.fetchResourcesByModuleIdentifier('audits', {
        columns: ["number", "type", "employee.id", "employee.brezel_name", "customer.id", "customer.brezel_name", "schedule.id", "schedule.brezel_name",
          "date", "inventory_number", "location", "level", "room",
          "device_type", "manufacturer", "device_model", "warming_device", "free_text"
        ],
        results: 100000,
        pre_filters: JSON.stringify([filters])
      })
          .then(response => {
            const audits = response.data
            const data = {}
            audits.forEach((audit) => {
              data[audit.id] = audit
            })
            store.commit('updateAuditsShallow', data)
          })
          .finally(() => {
            this.auditsDownloading = false
          })
    },
    fetchAllChecklists() {
      this.checklistsDownloading = true
      this.fetchResourcesByModuleIdentifier('checklists', {results: 100000})
          .then(response => {
            let responseData = response.data
            store.commit('setChecklists', responseData)
            if (responseData.length > 0) {
              // For the checklists to be completely cached, the list field needs to be accessible offline too.
              // Since we don't get list field data from the index route, we have to loop through the checklists and query them individually
              this.fetchResourcesDetail('checklists', responseData)
                  .then(async data => {
                    responseData = data
                    store.commit('setChecklists', responseData)
                    await this.cacheFilesFromChecklistResources(responseData)
                    this.checklistsDownloading = false
                  })
            } else {
              this.checklistsDownloading = false
            }
          })
          .catch(() => {
            this.checklistsDownloading = false
          })
    },
    fetchAllCustomers() {
      this.customersDownloading = true
      this.fetchResourcesByModuleIdentifier('customers', {results: 100000, columns: ["company"]})
          .then(response => {
            store.commit('setCustomers',
                response.data.map(customer => ({
                  id: customer.id,
                  brezel_name: customer.brezel_name,
                  module_id: customer.module_id,
                }))
            )
            this.customersDownloading = false
          })
          .catch(() => {
            this.customersDownloading = false
          })
    },
    fetchAllSchedules() {
      this.schedulesDownloading = true
      return this.fetchResourcesByModuleIdentifier('schedule', {
        results: 100000,
        columns: ["number", "timeframe", "customer", "maintopic"],
        pre_filters: JSON.stringify([
          {
            column: "date(timeframe.end)",
            operator: ">=",
            value: dayjs().startOf('day')
          },
          {
            column: "date(timeframe.start)",
            operator: "<=",
            value: dayjs().endOf('day')
          }
        ])
      })
          .then(response => {
            const data = response.data.filter(schedule => {
              if (schedule?.timeframe.end) {
                if (dayjs(schedule.timeframe.end).isSameOrAfter(dayjs(), 'day')) {
                  return true
                }
              }
              return false
            })
            store.commit('setSchedules', data)
            this.schedulesDownloading = false
            this.fetchAllAudits()
          })
          .catch(() => {
            this.schedulesDownloading = false
          })
    },
    fetchAllEquipment() {
      this.equipmentDownloading = true
      this.fetchResourcesByModuleIdentifier('equipment', {
        columns: ["name", "serial_number"],
        results: 100000,
      })
          .then(response => {
            store.commit('setEquipment', response.data)
          })
          .finally(() => {
            this.equipmentDownloading = false
          })
    },
    fetchAuditNumbers() {
      fetch(store.state.apiUrl + '/webhook/GetAllAuditNumbers/audits', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
        }
      })
          .then(response => response.json())
          .then(response => {
            store.commit('setAuditNumbers', response)
          })
    },
    fetchCurrentVersionFromRepo() {
      fetch("https://gitlab.kiwis-and-brownies.de/api/v4/projects/kibro%2FbasedOnBrezel%2Fphoenix-pruef-app/repository/commits", {
        headers: {
          'PRIVATE-TOKEN': process.env.VUE_APP_GITLAB_ACCESS_TOKEN
        }
      })
          .then(response => response.json())
          .then(response => {
            this.currentRepoVersion = response[0].short_id
          })
    },
    fetchData() {
      this.hasApiConnection().then(async () => {
        const now = Math.round(new Date().getTime() / 1000)
        if (store.state.lastChecklistReload < now - 60 * 60 * 24) {
          this.fetchAllChecklists()
          store.commit('setLastChecklistReload', now)
        }
        await this.updateUserData()
        this.fetchAllCustomers()
        this.fetchAllSchedules()
        this.fetchAllEquipment()
        this.fetchAuditNumbers()
        await this.fetchSignature()
      })
    },
    async fetchSignature() {
      if (!this.loggedIn) return
      let user = store.state.user
      if (!store.state.signature) {
        if (!store.state.user.module || !store.state.user.module.id || !store.state.user.module.identifier) {
          await this.fetchUserModule()
          user = store.state.user
        }
        fetch([store.state.apiUrl, 'modules', user.module.identifier, 'resources', user.id].join('/'), {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + user.accessToken,
          }
        })
            .then(response => response.json())
            .then(response => {
              if (response.signature) {
                store.commit('setSignature', response.signature)
              }
            })
      }
    },
    async updateUserData() {
      if (!store.state.user || !store.state.user.id) {
        return fetch(store.state.apiUrl + '/general', {
          headers: {
            'Authorization': 'Bearer ' + store.state.user.accessToken,
          }
        })
            .then(response => response.json())
            .then(response => {
              if (response.me) {
                store.commit('setUser', {
                  ...store.state.user || {},
                  email: response.me.email,
                  id: response.me.id,
                  name: response.me.brezel_name,
                  module: {
                    id: response.me.module_id,
                    identifier: response.me.module.identifier,
                  }
                })
              }
            })
      }
    },
    fetchUserModule() {
      return fetch(store.state.apiUrl + '/general', {
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
        },
      })
          .then(response => response.json())
          .then(response => {
            if (response.me?.module) {
              store.commit('setUserModule', {
                id: response.me.module.id,
                identifier: response.me.module.identifier,
              })
            }
          })
    },
    hasApiConnection() {
      this.queueActive = navigator.serviceWorker && navigator.serviceWorker.controller

      return fetch(store.state.apiUrl + '/checkAccessToken', {
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
        },
      })
          .then(response => {
            this.setOnline()
            if (response.status === 401) {
              this.logout()
            }
            return response.json()
          })
          .catch(() => this.setOnline(false))
    },
    install() {
      this.deferredPrompt.prompt()
    },
    logout() {
      store.commit('clearAccessToken')
      store.commit('setCreateFormData', {})
      this.drawerOpened = false
    },
    pingServer() {
      // Ping the server every 10 seconds to check if there is a connection
      window.setInterval(this.hasApiConnection, 10000)
    },
    retryQueue() {
      if (this.isOnline) {
        this.$workbox.messageSW({type: 'RETRY_ALL'})
      }
    },
    setOnline(online = true) {
      if (store.state.online !== online) {
        store.commit('setOnline', online)
      }
    }
  },
  mixins: [api],
  watch: {
    '$route'(to) {
      const left = to.path === '/'
      this.transition = left ? 'slide-left' : 'slide-right'
    },

    isOnline() {
      this.retryQueue()
    },

    isDownloading() {
      store.state.downloadingOfflineData = this.isDownloading
    },
  }
}
</script>

<style>
.alert-enter-active,
.alert-leave-active {
  transform: translateY(0%);
  transition: transform 0.5s, height 0.5s;
}

.alert-enter,
.alert-leave-to {
  transform: translateY(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  transition: transform 0.3s, opacity 0.3s;
}

.slide-left-enter-active,
.slide-right-enter-active {
  transform: translateX(0%);
}

.slide-right-enter,
.slide-left-leave-to {
  transform: translateX(100%);
}

.slide-left-enter,
.slide-right-leave-to {
  transform: translateX(-100%);
}
</style>
